<template>
  <div id="body">
    <div class="header">
      <!-- <p class="hometext">首页</p> -->
      <div class="hader_title" style="margin-left: 40px;">
        <div style="width: 100px;">
          <img src="../../assets/APP_logo.png" alt=""/>
<!--          <div>
            <el-popover placement="top-start" title="系统联系人" width="70" trigger="hover" content="" style="margin-right: 16px;">
              <span>{{this.agentAdmin.name}}</span>
              <br>
              {{this.agentAdmin.tel}}
              <el-image src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTY0NjYyMjkyOC45NTYxLjExNjc3"
                slot="reference"></el-image>
            </el-popover>
            <el-popover title="微信二维码" placement="top-start" width="70" trigger="hover" content="">
              <el-image style="width: 100px; height: 100px ;margin-left: 10px;" :src="this.agentAdmin.pic"></el-image>
              <br>
              <el-image src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTY0NjYyMjkyMy43NjIzLjQ0NDc5"
                slot="reference"></el-image>
            </el-popover>
          </div> -->
        </div>
        <div class="title_text">
          <p>{{ SchoolName }}</p>
          <p>ID:{{ SchoolID }}</p>
          <p>管理员:{{ SchoolAdmin }}</p>
        </div>
      </div>
      <div class="datalist">
        <div>
          <p style="color:#FF5A21">{{total}}</p>
          <p>总计检测人数</p>
        </div>
        <div>
          <p style="color:#3AA0FF">{{AddMyopiaCount}}</p>
          <p>本月新增近视人次</p>
        </div>
        <div>
          <p style="color:#1AC574">{{AddRecoveryCount}}</p>
          <p>本月恢复近视人次</p>
        </div>
        <div>
          <p style="color:#30CADC">{{MyopiaCount}}</p>
          <p>现存近视人数</p>
        </div>
        <div>
          <p style="color:#7C90E1">{{nowMyopialv}}</p>
          <p>近视率</p>
        </div>
      </div>
    </div>

    <div class="ClassInfo_box">
      <div class="dataAdmin">
        <p>数据管理</p>
      </div>
      <div class="ranking">
        <ul class="ranking">
          <li v-for="(item,index) in rankingList" :key="index" @click="Atpitch(index)"
            :class="currentIndex==index?'active':''">
            {{item}}
          </li>
        </ul>
      </div>
      <el-table :data="ClassInfos" @sort-change="sortChange">
        <el-table-column prop="ClassName" label="班级" align="center" width="150">
          <template slot-scope="scope">
            {{scope.row.TypeName}}{{scope.row.ClassName}}
          </template>
        </el-table-column>
        <el-table-column :prop="Rank" label="平均视力排名" width="120" align="center" sortable="custom">
          <!-- (统计昨天) -->
        </el-table-column>
        <el-table-column prop="ztpx" label="当前近视率排名" width="120" align="center" sortable="custom">
          <!-- (截止到昨天) -->
        </el-table-column>
        <!-- <el-table-column :prop="Rank" label="排名" sortable> </el-table-column> -->
        <!-- <el-table-column prop="ClassNum" label="班级人数" sortable></el-table-column> -->
        <el-table-column prop="ClassNum" label="班级人数" align="center"></el-table-column>

        <!-- <el-table-column sortable prop="TeaName" label="任教老师"></el-table-column> -->
        <el-table-column prop="TeaName" label="任教老师" align="center"></el-table-column>
        <!-- <el-table-column sortable  label="检测完成度"> -->
        <el-table-column label="当月检测完成度" width="300" align="center">
          <template slot-scope="scope">
            <el-progress :text-inside="true" :stroke-width="23" :percentage="scope.row.Completion"></el-progress>
          </template>
        </el-table-column>
        <el-table-column prop="monMyopialv" label="近视率" width="100" align="center"></el-table-column>
    <el-table-column prop="addMyopialv" width="160" label="较上月增长近视率" align="center"></el-table-column>
        <el-table-column prop="classMyopianum" label="近视人数" align="center"></el-table-column>
        <!-- <el-table-column label="操作" fixed="right"> -->
        <el-table-column label="操作" width="200" align="center">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" plain @click="handleMore(scope.$index, scope.row)">更多</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
          layout="total,prev, pager, next, jumper" :total="CountNum" style="margin-top:20px">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import {
    agentAdmin,
  } from '@/api/agentApi.js'
  import {
    Schoolhome,
    Schoolranking
  } from '@/api/user.js'
  export default {
    data() {
      return {
        agentAdmin: {}, // 系统联系人
        SchoolName: '暂无',
        SchoolID: '',
        SchoolAdmin: '暂无',
        total: '0', // 总计人数
        AddMyopiaCount: '暂无', // 新增近视人数
        AddRecoveryCount: '暂无', // 新增恢复人数
        MyopiaCount: '暂无', // 现存近视人数
        RecoveryCount: '暂无', // 现存恢复人数
        nowMyopialv: '暂无', // 现存近视人数
        ClassInfos: [], // 班级信息数组
        rankingList: ['校内排名', '同级排名', '同城排名'], // Table选项
        rankingList: ['校内排名'], // Table选项
        currentIndex: 0, // tag 索引
        pagesize: 10, // 每页显示条目个数
        currpage: 1, // 初始页
        CountNum: 0, // 总条数
        Rank: 'SchoolRank', // 排名
        order: '', // 排序
        ordertype: '' // 排序分类
      }
    },
    mounted() {
      // this.AgentAdmin() // 获取系统联系人
      this.UserInfo() // 获取学校信息
      this.myopia() // 获取学校视力近况数据
      this.ClassList('asc', 1, 'SchoolRank') // 学校排名数据
    },
    methods: {
      AgentAdmin() { // 获取系统联系人
        agentAdmin().then(res => {
          this.agentAdmin = res.data.list;
        }).catch(err => {
          console.log(err)
        })
      },
      Atpitch(index) { // 点击排名
        this.currentIndex = index
        switch (index) {
          case 0:
            this.Rank = 'SchoolRank'
            break
          case 1:
            this.Rank = 'ClassTypeRank'
            break
          case 2:
            this.Rank = 'CityRank'
            break
          default:
            this.Rank = 'SchoolRank'
        }
      },
      UserInfo() {
        const Users = JSON.parse(localStorage.getItem('userInfo'))
        // console.log(Users);
        this.SchoolName = Users.schoolName // 学校名字
        this.SchoolID = Users.schoolId // 学校ID赋值
        this.SchoolAdmin = Users.userName // 学校管理员
        // this.ClassList(); //获取班级排名
      },
      myopia() { // 视力近况数据
        Schoolhome().then(res => {
          // console.log(res);
          if (res.status.code == 1) {
            this.total = res.data.schoolCount // 总计人数
            this.AddMyopiaCount = res.data.newMyopia // 新增近视人数
            this.AddRecoveryCount = res.data.newRecovery // 新增恢复人数
            this.MyopiaCount = res.data.nowMyopia // 现存近视人数
            this.RecoveryCount = res.data.nowRecovery // 累计恢复人数
            this.nowMyopialv = res.data.nowMyopialv // 累计恢复人数
            this.monMyopialv = res.data.monMyopialv //
            this.addMyopialv = res.data.addMyopialv //
            this.classMyopianum = res.data.classMyopianum //
          }
        }).catch(err => {
          console.log(err)
        })
      },
      ClassList(order, currpage, ordertype) { // 班级排名数据
        const data = {
          page: currpage, //	当前页数
          limit: this.pagesize, // 每页的个数
          order: order, //排序
          ordertype: ordertype, //排序类别
        }

        Schoolranking(data).then(res => {
          // console.log(res)
          if (res.status.code == 1) {
            this.ClassInfos = res.data.List
            this.CountNum = parseInt(res.data.CountNum) // 总条数
          }
        }).catch(err => {
          console.log(err)
        })
      },
      handleMore(index, row) { // 更多
        // console.log(row)
        this.$router.push({
          name: 'teacherHome',
          params: {
            ClassID: row.ClassID,
            ClassType: row.ClassType
          }
        })
      },
      handleCurrentChange(val) {

        this.ClassList(this.order, val, this.ordertype)
        this.currpage = val
      },
      sortChange(column) { //排序

        if (column.order != null) {
          this.order = column.order.replace('ending', '')
        } else {
          this.order = 'asc';
        }
        this.ordertype = column.prop
        this.ClassList(this.order, this.currpage, this.ordertype);
      },
    }
  }
</script>
<style lang="scss" scoped>
  #body {
    // height: 100%;
    background: #f0f2f5;
  }

  .header {
    display: flex;
    height: 130px;
    align-items: center;
    background: #ffffff;

    .hometext {
      font-size: 14px;
      align-self: flex-start;
      margin-left: 22px;
      font-family: PingFangSC-Regular;
      color: rgba(0, 0, 0, 0.45);
    }

    .hader_title {
      display: flex;
      margin-left: 5px;
      align-items: center;

      .title_text {
        margin-left: 24px;

        p:nth-child(1) {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.85);
          font-family: PingFangSC-Medium;
          font-weight: 600;
        }

        p:nth-child(2) {
          font-size: 14px;
          font-weight: normal;
          color: rgba(0, 0, 0, 0.85);
        }

        p:nth-child(3) {
          font-size: 14px;
          font-weight: normal;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      img {
        width: 72px;
        height: 72px;
        border-radius: 50%;
      }
    }

    .datalist {
      display: flex;
      width: 473px;
      height: 70px;
      margin-left: 200px;
      justify-content: space-around;

      div {
        p:nth-child(1) {
          font-size: 20px;
          font-weight: normal;
        }

        p:nth-child(2) {
          font-size: 14px;
          font-weight: normal;
          color: #000000;
        }
      }

    }
  }

  .ClassInfo_box {
    width: 98%;
    // height: 650px;
    margin: 0 auto;
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    background: white;

    .dataAdmin {
      background: #ffffff;
      display: flex;
      border-bottom: 2px solid #E9E9E9;

      p {
        margin-left: 32px;
        font-size: 16px;
        font-weight: normal;
        color: #000000;
      }
    }

    .ranking {
      display: flex;
      align-items: center;
      height: 68px;
      background: #ffffff;

      // background:yellowgreen;
      ul {
        padding: 0;
        margin: 0;

        li {
          list-style: none;
          font-size: 12px;
          font-weight: normal;
          // color: #575757;
          font-family: 萍方-简;
          margin-left: 18px;
          margin-top: 6px;
        }

        li:hover {
          cursor: pointer
        }
      }
    }
  }

  .active {
    //检测人滑动点击css
    display: flex;
    color: #1890FF;
    font-size: 16px;
    font-weight: 500;
    padding: 20px 0 20px 0;
    border-bottom: 2px solid #1890FF;
  }
</style>
